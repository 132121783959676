import React, { useState } from 'react';
import { toast } from 'react-toastify';
import Switch from 'react-ios-switch';
import DatePicker from "react-datepicker";
import Dropzone from './dropzone.js';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
function UploadPhoto() {
    const [startDate, setStartDate] = useState(0);
    const [photo, setPhoto] = useState(null);
	const [nsfw, setNSFW] = useState(false);
	const [willRedirect, setWillRedirect] = useState(true);
	const history = useHistory();
	
	const getPhotoData = (photoData) => {
		setPhoto(photoData);
		console.log(photoData);
		console.log(typeof(photoData));
		document.getElementById("photoName").value = photoData.path;
		setStartDate(photoData.lastModified);
	}

	const sendPhoto = () => {
		if(!document.getElementById("photoName").value || !photo) return toast.error(`Missing input value.`, {
			position: "top-center",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined
		});
		let formData = new FormData();

		const photoData = {
			lastModified: photo.lastModified,
			name: photo.name,
			path: photo.path,
			preview: photo.preview,
			size: photo.size,
			type: photo.type,
			webkitRelativePath: photo.webkitRelativePath
		};

		if(nsfw) photo.name = `SPOILER_${photo.name}`;
		formData.append('file', photo);
		formData.append('payload_json', JSON.stringify({
			"username": document.getElementById("photographer").value || "unknown"
		}));
		axios({
            method: 'POST',
			url: `https://discordapp.com/api/webhooks/782245409312997397/gXtSwFlPypdQJbf5Gouc3sud7KNWdcKuK4pc6sjYAU8M03MEHdUmc-Knc2ulE54PUrgl`,
			data: formData,
			headers: {'Content-Type': 'multipart/form-data' }
        }).then(() => {
			document.getElementById("photoName").value = "";
			document.getElementById("photographer").value = "";
			setStartDate(0);
			setNSFW(false);
			document.getElementById("photo").value = "";
			document.getElementById("preview").removeChild(document.getElementById("preview").children[0]);
			if(!willRedirect) history.push('/photos');
			else {
				toast.success(`📤 Photo uploaded successfully.`, {
					position: "bottom-left",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: false,
					progress: undefined
				});
			}
		}).catch((e) => {
			console.error(e);
		})
	}

	return (
		<div id="writeLetter">
			<div id="details">
				<input name="title" type="text" placeholder="Photo name" id="photoName" required/>
				<input name="photographer" type="text" placeholder="Photographer" id="photographer" required/>
				<DatePicker
					selected={startDate}
					onChange={date => setStartDate(date)}
					showTimeSelect
					timeFormat="HH:mm"
					timeIntervals={1}
					timeCaption="Time"
					dateFormat="MMMM d, yyyy h:mm aa"
					withPortal
					placeholderText="Set a specific date"
					name="date"
					id="dateModified"
				/>
				<div className="switch">
					<p className="label">Photo NSFW</p>
					<Switch name="nsfw" checked={nsfw} onChange={checked => setNSFW(checked)} onColor="rgb(241, 86, 86)" />
				</div>
				<div className="switch">
					<p className="label">Prevent redirect</p>
					<Switch name="noRedirect" checked={willRedirect} onChange={checked => setWillRedirect(checked)} />
				</div>
				<input type="submit" value="Publish" onClick={sendPhoto}/>
			</div>
			<div id="right">
				<Dropzone getPhotoData={getPhotoData}/>
			</div>
		</div>
	);
}

export default UploadPhoto;