import React, { useEffect, useState } from 'react';
import '../assets/scss/letters.scss';

export default function LetterPreview(props) {
    const [read, setRead] = useState(false);

    useEffect(() => {
        if(!localStorage.getItem("readLetters")) localStorage.setItem("readLetters", "");
        if(localStorage.getItem("readLetters").includes(`${props.id},`)) setRead(true);
    }, [props.checkRead]);

	return (
		<div className={read ? `letter nsfw-${props.nsfw}` : `letter nsfw-${props.nsfw} unread`} id={"letter-"+props.id.toString()} onClick={() => props.toggleLetter(props.id)}>
            <h6 className="letter-title">{props.nsfw ? <span className="title-icon" title="This letter contains NSFW content"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ff6a6a" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z"/><path d="M12 9v2m0 4v.01" /><path d="M5.07 19H19a2 2 0 0 0 1.75 -2.75L13.75 4a2 2 0 0 0 -3.5 0L3.25 16.25a2 2 0 0 0 1.75 2.75" /></svg></span> : ''} {parseInt(props.id) === 1 ? <span className="title-icon" title="Pinned"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#78e065" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"><circle cx="11.5" cy="8.5" r="5.5"/><path d="M11.5 14v7"/></svg></span> : ''} {props.nsfw ? 'Click letter to reveal title.' : props.title}</h6>
            <p className={props.nsfw ? "letter-preview-nsfw" : "letter-preview"}>{props.nsfw ? <span className="preloaded"></span> : props.content.substr(0,100).split(" ").slice(0, -1).join(" ") + "..."}</p>
        </div>
	);
}