import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../assets/scss/create.scss';
import Dropdown from '../components/dropdown.js';
import WriteLetter from '../components/writeLetter.js';
import UploadPhoto from '../components/uploadPhoto.js';

function Create() {
	const [propToRender, setPropToRender] = useState(<WriteLetter />);

	const onDropdownSelect = (index) => {
		if(index === 0){
			localStorage.setItem("CreatePage-ComponentIndex", 0)
			setPropToRender(<WriteLetter />);
		} else if(index === 1){
			localStorage.setItem("CreatePage-ComponentIndex", 1)
			setPropToRender(<UploadPhoto />);
		} else if(index === 2){
			localStorage.setItem("CreatePage-ComponentIndex", 2)
			setPropToRender("B");
		} else {
			localStorage.setItem("CreatePage-ComponentIndex", 0)
			setPropToRender(<WriteLetter />);
		}
	}

	useEffect(() => {
		document.title = "A letter | Create post"
		const toShowIndex = localStorage.getItem("CreatePage-ComponentIndex")
		onDropdownSelect(parseInt(toShowIndex))
	}, [])

	return (
		<div id="create">
			<ToastContainer />
			<div className="page-content">
				<h3 id="heading">Create a new post</h3>
				<h3 id="subtitle">Here you are able to upload many things to the website.</h3>
				<div id="post">
					<label htmlFor="dropdown-1">Select a post type</label>
					<Dropdown selected={localStorage.getItem("CreatePage-ComponentIndex")} index="1" title={'Select a post type'} list={['Write a letter', 'Upload a photo', 'Link a book']} onSelect={onDropdownSelect}/>
					{propToRender}
				</div>
			</div>
		</div>
	);
}

export default Create;