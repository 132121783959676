import React, { useEffect, useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import '../assets/scss/letters.scss';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import LetterPreview from '../components/letterPreview.js';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Letter from '../components/newletter';

export default function Blog() {
    const [letters, setLetters] = useState([]);
    const [firstLetterDate, setFirstLetterDate] = useState(0);
    const [letterCount, setLetterCount] = useState(0);
    const totalWordCount = useRef(0);

    const [letterOpen, setLetterOpen] = useState(false);
    const [showingLetter, setShowingLetter] = useState(null);

    const [checkRead, setCheckRead] = useState(false);
    useEffect(() => {
        axios({
            method: 'GET',
            url: `https://luvbulb.alexsmith.space/.netlify/functions/get?select=all`
        }).then(res => {
            res.data.letters.forEach(letter => {
                totalWordCount.current = totalWordCount.current + letter.content.split(" ").length;
                return setLetters(oldLetters => [...oldLetters, letter]);
            });
            setLetterCount(res.data.letters.length);
            setFirstLetterDate(parseInt(res.data.letters[0].date));
            document.querySelectorAll(".preloaded").forEach(element => element.remove());
            document.getElementById("stats").style.display = 'inline-block';
        }).catch(e => {
            console.log(e);
            toast.error('Uh oh! The letters couldn\'t be found :(', {
                position: 'top-center',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
            });
        });
    }, []);

    const toggleLetter = (id) => {
        setShowingLetter(id);
        setLetterOpen(!letterOpen);
        setCheckRead(!checkRead);
    }

	return (
		<div id="letters">
            <ToastContainer />
            {letterOpen ? <Letter id={showingLetter} closeLetter={toggleLetter}/> : ''}
            <div className="page-content">
                <h3>Letters</h3>
                <p className="subheading">All of the letters that have been written can be found below.</p>
                <section id="letters-preview">
                    <SimpleBar style={{ maxHeight: '500px' }}>
                        {letters.map((letter, i) => (
                            <LetterPreview key={i} nsfw={letter.nsfw} single={false} id={letter._id} title={letter.title} author={letter.author} date={letter.date} content={letter.content} toggleLetter={toggleLetter} checkRead={checkRead}/>
                        ))}
                    </SimpleBar>
                    <div className="preloaded">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="preloaded">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="preloaded">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="preloaded">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="preloaded">
                        <div></div>
                        <div></div>
                    </div>
                </section>
                <div id="stats" style={{ display: 'none' }}>
                    <h4>Details</h4>
                    <p>Since <span id="first-letter">{`${new Date(firstLetterDate).getDate()}/${new Date(firstLetterDate).getMonth() + 1}/${new Date(firstLetterDate).getFullYear()}`}</span> ({Math.floor((new Date().getTime() - new Date(firstLetterDate).getTime()) / (1000 * 3600 * 24))} Days), I have been able to write <span id="letter-count">{letterCount}</span> letters. Totalling exactly <span id="word-count">{totalWordCount.current.toLocaleString('en-AU')}</span> words.</p>
                    <p>I have attempted to write a letter for you every day since I started this project. Some days were harder than others to write about, you will see that in the varying length of each of the letters.</p>
                    <p>Please enjoy everything this website has to offer, I know that it is not a lot but I want it to be special for you.</p>
                    <p id="">I love you so much Emelie.</p>
                </div>
            </div>
		</div>
	);
}
