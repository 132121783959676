import React, { useEffect, useState } from 'react';
import axios from 'axios';

export default function Letter(props) {

    const [canShow, setCanShow] = useState(false);
    const [showing, setShowing] = useState(false);

    const [letterTitle, setLetterTitle] = useState(null);
    const [letterAuthor, setLetterAuthor] = useState(null);
    const [letterContent, setLetterContent] = useState(null);
    const [letterDateInt, setLetterDateInt] = useState(0);
    const [letterNSFW, setLetterNSFW] = useState(false);

    const [letterWordCount, setLetterWordCount] = useState(0);

    axios({
        method: 'GET',
        url: `https://luvbulb.xyz/.netlify/functions/get?select=singular&letter=${props.id}`
    }).then(res => {
        const letterData = res.data.letter[0];
        setLetterTitle(letterData.title);
        setLetterAuthor(letterData.author);
        setLetterContent(letterData.content);
        setLetterWordCount(letterData.content.split(" ").length);
        setLetterDateInt(parseInt(letterData.date));
        setLetterNSFW(letterData.nsfw);
    
        document.getElementById("letter-body").innerText = "";
        letterContent.split("\n\n").forEach((paragraph, i) => {
            paragraph = paragraph.replace(/\*{2}(.*?)\*{2}/gi, (match, token) => { return `<b>${token}</b>` });
            paragraph = paragraph.replace(/\*{1}(.*?)\*{1}/gi, (match, token) => { return `<i>${token}</i>` });
            paragraph = paragraph.replace(/\|{2}(.*?)\|{2}/gi, (match, token) => {
                let hidden = token.split(",");
                return `<span class="hiddenMessage" aria-label="${hidden[1].trim()}" data-balloon-pos="up">${hidden[0]}</span>`
            });
            paragraph = paragraph.replace(/\[{1}(.*?)\]{1}/gi, (match, token) => {
                let details = token.split(",");
                return `<div class="letterImage"><img width="500px" src="${process.env.PUBLIC_URL}/letterImages/${details[0]}.png" alt="${details[1]}"/><span class="caption">${details[1]}</span</div>`
            });

            let element = document.createElement("p");
            element.innerHTML = paragraph;
            document.getElementById("letter-body").appendChild(element);
        });
        setCanShow(true);
    }).catch(e => {
        console.log(e);
    }); 

    useEffect(() => {
        if(!localStorage.getItem("readLetters").includes(`${props.id},`)) {
            setTimeout(() => {
                localStorage.setItem("readLetters", `${localStorage.getItem("readLetters")}${props.id},`);
            }, 1000);
        }
    }, [])
    
    const close = () => {
        document.getElementById("letter").classList.add('close');
        setTimeout(() => {
            props.closeLetter(props.id)
        }, 800);
    }

	return (
		<div id="letter" className={canShow ? 'viewable' : ''}>
            <div id="letter-controls">
                <span onClick={() => close()}><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 24 24" fill="none" stroke="#17171d" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></span>
            </div>
            <div id="letter-header">
                <h3>{letterTitle}</h3>
                <p className="subheading">Written by: {letterAuthor} • Published on: {new Date(letterDateInt).getDate().toString().length === 1 ? '0' + new Date(letterDateInt).getDate().toString() : new Date(letterDateInt).getDate().toString()}/{(new Date(letterDateInt).getMonth() + 1).toString().length === 1 ? '0' + (new Date(letterDateInt).getMonth() + 1).toString() : new Date(letterDateInt).getMonth().toString()}/{new Date(letterDateInt).getFullYear().toString().substr(2, 2)}</p>             
            </div>
            <div id="letter-content">
                <p id="letter-body">{letterContent}</p>
                <p id="letter-wordcount">Word count: {letterWordCount}</p>
            </div>
        </div>
	);
}