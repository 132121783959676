import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import '../assets/scss/letters.scss';
import 'balloon-css';

export default function Letter(props) {

    const [inView, setInView] = useState(0);
    
    let date = new Date(props.date);
    const notify = () => {
        navigator.clipboard.writeText(`${window.location.origin}/letter/${props.id}`).then(function() {
            toast.dark(`🔗 Copied link to clipboard`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                onClick: () => {
                    window.open(`${window.location.origin}/letter/${props.id}`, "_blank")
                }
            });
        }, function() {
            return toast.error(`Unable to copy link.`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
            });
        });
    }

    useEffect(() => {
        document.getElementById("paragraphs-" + props.id).innerText = "";
        props.content.split("\n\n").forEach((paragraph, i) => {
            paragraph = paragraph.replace(/\*{2}(.*?)\*{2}/gi, (match, token) => { return `<b>${token}</b>` });
            paragraph = paragraph.replace(/\*{1}(.*?)\*{1}/gi, (match, token) => { return `<i>${token}</i>` });
            paragraph = paragraph.replace(/\|{2}(.*?)\|{2}/gi, (match, token) => {
                let hidden = token.split(",");
                return `<span class="hiddenMessage" aria-label="${hidden[1].trim()}" data-balloon-pos="up">${hidden[0]}</span>`
            });
            paragraph = paragraph.replace(/\[{1}(.*?)\]{1}/gi, (match, token) => {
                let details = token.split(",");
                return `<div class="letterImage"><img width="500px" src="${process.env.PUBLIC_URL}/letterImages/${details[0]}.png" alt="${details[1]}"/><span class="caption">${details[1]}</span</div>`
            });

            let element = document.createElement("p");
            element.innerHTML = paragraph;
            document.getElementById("paragraphs-" + props.id).appendChild(element);
        });
        if(props.nsfw) {
            document.getElementById('removeNSFW-'+props.id.toString()).addEventListener("click", () => {
                document.title = props.title;
                document.getElementById("letter-"+props.id.toString()).classList.remove('nsfw-true');
                document.getElementById("letter-"+props.id.toString()).removeChild(document.getElementById("letter-"+props.id.toString()).firstChild)
            })
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if(props.nsfw && props.isVisible) document.title = props.title.replace(/[a-zA-Z]/g, "▉");
        else if(props.isVisible && !props.nsfw) {
            document.title = props.title;
            setInView(props.id);
            document.querySelectorAll('[data-id]').forEach((e) => {
                if(e.getAttribute('data-id') !== props.id && e.classList.contains('active')) e.classList.remove('active');
            })
            document.querySelectorAll('[data-id="' + props.id + '"]')[0].classList.add('active');
        }
    }, [props.isVisible]); // eslint-disable-line

    const colours = [
        '#FFADAD',
        '#FFD6A5',
        '#FDFFB6',
        '#CAFFBF',
        '#9BF6FF',
        '#A0C4FF',
        '#BDB2FF',
        '#FFC6FF'
    ];

	return (
		<div className={"letter nsfw-"+props.nsfw} id={"letter-"+props.id.toString()}>
            {props.nsfw ?
            <div className="warning">
                <h1>This letter may contain content that is NSFW.</h1>
                <h3>Click the button below to read the letter.</h3>
                <span id={"removeNSFW-"+props.id.toString()}>Read</span>
            </div> : ''}

            <header>
                <h1 className="title">{props.title}{props.single ? '' : <span title="Copy link to this letter." className="letterLink" onClick={notify}><svg width="17px" focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M314.222 197.78c51.091 51.091 54.377 132.287 9.75 187.16-6.242 7.73-2.784 3.865-84.94 86.02-54.696 54.696-143.266 54.745-197.99 0-54.711-54.69-54.734-143.255 0-197.99 32.773-32.773 51.835-51.899 63.409-63.457 7.463-7.452 20.331-2.354 20.486 8.192a173.31 173.31 0 0 0 4.746 37.828c.966 4.029-.272 8.269-3.202 11.198L80.632 312.57c-32.755 32.775-32.887 85.892 0 118.8 32.775 32.755 85.892 32.887 118.8 0l75.19-75.2c32.718-32.725 32.777-86.013 0-118.79a83.722 83.722 0 0 0-22.814-16.229c-4.623-2.233-7.182-7.25-6.561-12.346 1.356-11.122 6.296-21.885 14.815-30.405l4.375-4.375c3.625-3.626 9.177-4.594 13.76-2.294 12.999 6.524 25.187 15.211 36.025 26.049zM470.958 41.04c-54.724-54.745-143.294-54.696-197.99 0-82.156 82.156-78.698 78.29-84.94 86.02-44.627 54.873-41.341 136.069 9.75 187.16 10.838 10.838 23.026 19.525 36.025 26.049 4.582 2.3 10.134 1.331 13.76-2.294l4.375-4.375c8.52-8.519 13.459-19.283 14.815-30.405.621-5.096-1.938-10.113-6.561-12.346a83.706 83.706 0 0 1-22.814-16.229c-32.777-32.777-32.718-86.065 0-118.79l75.19-75.2c32.908-32.887 86.025-32.755 118.8 0 32.887 32.908 32.755 86.025 0 118.8l-45.848 45.84c-2.93 2.929-4.168 7.169-3.202 11.198a173.31 173.31 0 0 1 4.746 37.828c.155 10.546 13.023 15.644 20.486 8.192 11.574-11.558 30.636-30.684 63.409-63.457 54.733-54.735 54.71-143.3-.001-197.991z" class=""></path></svg></span>}</h1>
                <h3 className="subheading">{props.author} <span style={{color: colours[Math.floor(Math.random() * colours.length)]}}>❤︎</span> | <span title={Intl.DateTimeFormat('default', { hour12: true, hour: 'numeric', minute: 'numeric' }).format(date)}>{date.getDate().toString().length === 1 ? '0' + date.getDate().toString() : date.getDate().toString()}/{(date.getMonth() + 1).toString().length === 1 ? '0' + (date.getMonth() + 1).toString() : date.getMonth().toString()}/{date.getFullYear().toString().substr(2, 2)}</span></h3>
            </header>
            <article id={'paragraphs-' + props.id} className="paragraphs">Loading letter content.</article>
        </div>
	);
}