import React from 'react';
import { Link } from 'react-router-dom';

export default function Navigation(props) {
	return (
        <div id="navigation">
            <h3><Link to="/">luvbulb</Link></h3>
            <ul>
                <li className={props.active === 'letters'  ? 'active' : ''}><Link to="/letters">Letters</Link></li>
                <li className={props.active === 'photos' ? 'active' : ''}><li><a rel="noopener noreferrer" href="https://discordapp.com/channels/700893675542151218/737211697072308265/" target="_blank">Photos</a></li></li>
            </ul>
            <Link to="/create" id="create-btn">Create a post</Link>
        </div>
	);
}