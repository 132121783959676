import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './assets/scss/main.scss';
import Home from './pages/home.js';
import Blog from './pages/blog.js';
import SingleLetter from './pages/letter.js';
import Create from './pages/create.js';
import Navigation from './components/nav.js';

ReactDOM.render(
	<React.StrictMode>
        <Router>
        	<Switch>
              	<Route exact path="/">
					<Home />
              	</Route>
				<Route path="/letters">
					<Navigation active={'letters'}/>
					<Blog />
				</Route>
				<Route path="/letter/">
					<Navigation />
					<SingleLetter active={'letters'} />
				</Route>
				<Route path="/books">
					<Navigation active={'books'}/>
					<h1>books</h1>
				</Route>
				<Route path="/create">
					<Navigation active={'create'} />
					<Create />
				</Route>
          </Switch>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

