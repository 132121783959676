import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import '../assets/scss/letters.scss';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import Letter from '../components/letter.js';

function SingleLetter() {
    const [letters, setLetters] = useState([]);

    useEffect(() => {
        axios({
            method: 'GET',
            url: `https://luvbulb.alexsmith.space/.netlify/functions/get?id=all`
        }).then(res => {
            if(res.data.length < parseInt(window.location.pathname.split("/")[2])){
                window.history.pushState('', '', '/letter/'+(res.data.length).toString())
                return setLetters(oldLetters => [...oldLetters, res.data[res.data.length - 1]]);
            }
            res.data.forEach(letter => {
                if(letter.id.toString() !== window.location.pathname.split("/")[2]) return;
                return setLetters(oldLetters => [...oldLetters, letter]);
            })
        })
    }, [])

	return (
		<div id="letters">
            <ToastContainer />
            <div id="content">
                {letters.map(letter => (
                    <Letter single={true} id={letter._id} title={letter.title} author={letter.author} date={letter.date} content={letter.content} />
                ))}
            </div>
		</div>
	);
}

export default SingleLetter;
