import React, { useState, useEffect } from 'react';

export default function Dropdown(props) {
    const [selectedItem, setSelectedItem] = useState(0); // eslint-disable-line

    const dropdownToggle = () => {
        document.getElementById("dropdown-"+props.index.toString()).classList.toggle("active");
        document.getElementById("dropdown-"+props.index.toString()).lastChild.classList.toggle("visible");
    }

    const selectItem = (item) => {
        setSelectedItem(item);
        document.getElementsByClassName("dropdown-header-title")[0].innerHTML = props.list[item];
        dropdownToggle();
        if(selectItem !== item) props.onSelect(item);
    }

    useEffect(() => {
        if(props.selected) {
            setSelectedItem(parseInt(props.selected));
            document.getElementsByClassName("dropdown-header-title")[0].innerHTML = props.list[parseInt(props.selected)];    
        }

        document.addEventListener("click", (event) => {
            if(!document.getElementById("dropdown-"+props.index.toString())) return;
            if(event.target.offsetParent === document.getElementById("dropdown-"+props.index.toString())) return;
            if(document.getElementById("dropdown-"+props.index.toString()).classList.contains("active")
            && (event.target !== document.getElementById("dropdown-"+props.index.toString())
            || event.target.offsetParent !== document.getElementById("dropdown-"+props.index.toString()))) {
                dropdownToggle();
            }
        });
    }, []); // eslint-disable-line

	return (
		<div className="dropdown-wrapper" id={"dropdown-"+props.index}>
            <div className="dropdown-header" onClick={() => dropdownToggle()}>
                <p className="dropdown-header-title">{props.list[0]}</p>
            </div>
            <div className="dropdown-list">
                {props.list.map((item, i) => (
                    <p className="dropdown-list-item" onClick={() => selectItem(i)}>{item}</p>
                ))}
            </div>
        </div>
	);
}