import React, { useMemo, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    flex: 1,
    height: '530px',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export default function Dropzone(props) {
    const [files, setFiles] = useState([]);
    const {
        acceptedFiles, // eslint-disable-line
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles =>  {
            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isDragActive,
        isDragReject,
        isDragAccept
    ]);

    useEffect(() => {
        if(files.length <= 0) return;
        props.getPhotoData(files[0]);
        files.forEach(file => {
            const node = document.createElement('div');
            node.classList.add('previewPhoto')
            node.style.backgroundImage = `url('${file.preview}')`;
            document.getElementById('preview').appendChild(node);
        })
        // document.getElementById('previewPhoto').style.backgroundImage = `url('${files[0].preview}')`
    }, [files]) // eslint-disable-line

    return (
        <div className="container">
            <div {...getRootProps({style})} id="dropzone">
                <input id="photo" name='photo' {...getInputProps()} required/>
                <p>Drag 'n' drop some files here, or click to select files</p>
                <div id="preview"></div>
            </div>
        </div>
    );
}