import React, { useEffect, useState } from 'react';
import Switch from 'react-ios-switch';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
function WriteLetter() {
    const [letterDate, setLetterDate] = useState(0);
    const [saveStatus, setSaveStatus] = useState(false);
    const [wordCount, setWordCount] = useState(0);
    const [nsfw, setNSFW] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if((localStorage.getItem("letter-content") && localStorage.getItem("letter-title")) && (localStorage.getItem("letter-content").length > 0 || localStorage.getItem("letter-title").length > 0)) {
            document.getElementById("content").value = localStorage.getItem("letter-content")
            setWordCount(localStorage.getItem("letter-content").split(" ").length)
            document.getElementById("title").value = localStorage.getItem("letter-title")
            document.getElementById("author").value = localStorage.getItem("letter-author")
            setLetterDate(parseInt(localStorage.getItem("letter-date")))
            setNSFW(localStorage.getItem("letter-nsfw") === 'true' ? true : false);
            setSaveStatus(true);
        }

        let typingTimer;
        let typingInterval = 5000;
        document.getElementById("content").addEventListener("keyup", (e) => {
            clearTimeout(typingTimer);
            typingTimer = setTimeout(() => {
                if(!document.getElementById("content")) return clearTimeout(typingTimer);
                localStorage.setItem("letter-content", document.getElementById("content").value);
                localStorage.setItem("letter-title", document.getElementById("title").value);
                localStorage.setItem("letter-author", document.getElementById("author").value);
                localStorage.setItem("letter-date", letterDate);
                localStorage.setItem("letter-nsfw", nsfw);
                setSaveStatus(true);
            }, typingInterval);
        });

        document.getElementById("content").addEventListener("keydown", (e) => {
            clearTimeout(typingTimer);
        })
    }, []); //eslint-disable-line

    const contentChanged = (e) => {
        setWordCount(e.target.value.split(" ").length);
        setSaveStatus(false);
    }

    const clearValues = () => {
        localStorage.setItem("letter-content", '');
        localStorage.setItem("letter-title", '');
        localStorage.setItem("letter-author", '');
        localStorage.setItem("letter-date", '');
        localStorage.setItem("letter-nsfw", '');
        document.getElementById("content").value = '';
        document.getElementById("title").value = '';
        document.getElementById("author").value = '';
        setLetterDate(0)
        setNSFW(false);
    }

    const sendLetter = () => {
        let formData = new FormData();

        formData.append('title', document.getElementById("title").value);
		formData.append('author', document.getElementById("author").value)
		formData.append('content', document.getElementById("content").value)
		formData.append('date', letterDate);
        formData.append('nsfw', nsfw)
        
        axios({
            method: 'POST',
			url: `https://luvbulb.xyz/.netlify/functions/post`,
			data: {
                title: document.getElementById("title").value,
                author: document.getElementById("author").value || "lecks",
                content: document.getElementById("content").value,
                date: letterDate === 0 ? new Date().getTime() : new Date(letterDate).getTime(),
                nsfw
            },
            headers: { 'Content-Type': 'application/json' }
        }).then((res) => {
            clearValues();
            // history.push('/letter/' + res.data.id.toString())
		});
    }

	return (
		<div id="writeLetter">
            <div id="details">
                <input name="title" type="text" placeholder="Letter title" id="title" required/>
                <input name="author" type="text" placeholder="Author name" id="author" />
                <DatePicker
                    selected={letterDate}
                    onChange={date => setLetterDate(date)}
                    showTimeInput
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    withPortal
                    placeholderText="Set a specific date"
                    name="date"
                    id="date"
                />
                <div className="switch">
                    <span className="label">Letter NSFW</span>
                    <Switch name="nsfw" checked={nsfw} onChange={checked => setNSFW(checked)} onColor="rgb(241, 86, 86)" />
                </div>
                <div id="information">

                    <dl>
                        <dd>Word count</dd>
                        <dt>{wordCount}</dt>
                        <dd>Status</dd>
                        <dt>{saveStatus ? 'Saved' : 'Not saved'}</dt>
                    </dl>
                </div>
                <input type="submit" value="Publish" onClick={sendLetter}/>
            </div>
            <div id="right">
                <textarea id="content" name="content" placeholder="Write the letter content here!" onChange={contentChanged} required/>
            </div>
		</div>
	);
}

export default WriteLetter;