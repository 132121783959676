import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../assets/scss/home.scss';

export default function Home() {

	useEffect(() => {
		document.addEventListener('contextmenu', (e) => {
			e.preventDefault();
		});

		return () => {
			document.removeEventListener('contextmenu', () => {
				return;
			});
		}
	}, [])

	return (
		<div id="home">
			<div id="opening-container">
				<div id="opening-text-cover">
					<h2 id="opening-text">luvbulb</h2>
				</div>
			</div>
			<div id="center-container">
				<div id="center-container-text-content">
					<div id="center-text-area">
						<h2 id="main-text">luvbulb</h2>
					</div>
					<p>I spent a lot of time making and perfecting this. Take care and enjoy.</p>
				</div>
			</div>
			<div id="left-side"></div>
			<div id="content">
				<div id="content-navigation">
					<h3><Link to="/">luvbulb</Link></h3>
					<ul>
						<li><Link to="/letters">Letters</Link></li>
						<li><a href="https://discordapp.com/channels/700893675542151218/737211697072308265/">Photos</a></li>
						{/* <li><Link to="/books">Books</Link></li> */}
					</ul>
					<Link to="/create" id="create-button"><span>Create a post</span></Link>
				</div>
				<div id="content-left">
					<a href="https://alexsmith.space/" target="_blank" rel="noopener noreferrer">by Alex Smith</a>
				</div>
			</div>
		</div>
	);
}